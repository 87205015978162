import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/layout";

export default () => {

  return (
    <Layout>

    <div style={{ 'position': 'relative' }}>
      <Link to='/oer-filmmusik' className="closeX"><span>X</span></Link>
      <h2>Projekte zur OER Filmmusik</h2>
    </div>
    <div style={{ 'marginTop': '20px', 'display': 'flex' }}>
      <p>
        In dem OpenBook <a href="/Filmmusik">Filmmusik</a> wird die Eröffnungssequenz des Films <i>Sintel</i> besprochen und zum Komponieren einer eigenen Filmmusik zu dieser Szene angeregt. Auf dieser Seite können Sie den Anfang des Sintel-Films im Original hören. Der Komponist und Sounddesigner <a href="https://www.janmorgenstern.com/" target="_blank">Jan Morgenstern</a> war darüber hinaus so freundlich, uns für das OpenBook die Tonspur mit den Stimmen und Geräuschen zur Verfügung zu stellen. Hören Sie sich die Eröffnungssequenz des Sintel-Films<br /><br />
          a) nur mit Musik,<br />
          b) nur mit Geräuschen/Lauten und<br />
          c) in der originalen Abmischung an.<br /><br />
          Die unterschiedlichen Filmspuren vergleichen zu können, ist eine einmalige Gelegenheit, die uns Jan Morgenstern ermöglicht hat und für die ich ihm an dieser Stelle ganz herzlich danken möchte! Bitte beachten Sie, dass die Linzenz für die Filmmusik (<a href="https://creativecommons.org/licenses/by-nc-nd/3.0/">Creative Commons Attribution-Noncommercial-No Derivative Works 3.0 Unported</a>) im Gegensatz zur Lizenz des Films (<a href="https://creativecommons.org/licenses/by/3.0/">Creative Commons Attribution 3.0</a>) weder Veränderungen noch eine kommerzielle Nutzung erlaubt.
      </p>
      <a href="https://www.janmorgenstern.com/"><img src="https://www.janmorgenstern.com/assets/2015/09/jan-cartoon-296x300-296x300.png" alt="" /></a>
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div className="gridBox">
      <div className="gridFraction">
        <video style={{ 'maxWidth': '100%' }} poster="/images/poster-sintel.png" controls>
          <source src="/publicfiles/sintel/sintel-morgenstern-musik.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="gridFraction">
        Eröffnungssequenz nur mit Musik<br />
        Musik: Jan Morgenstern
      </div>
      <div className="gridFraction">
        <video style={{ 'maxWidth': '100%' }} poster="/images/poster-sintel.png" controls>
          <source src="/publicfiles/sintel/sintel-morgenstern-dfx.mp4" />
        </video>
      </div>
      <div className="gridFraction">
        Eröffnungssequenz nur mit Musik<br />
        Musik: Jan Morgenstern
      </div>
      <div className="gridFraction">
        <video style={{ 'maxWidth': '100%' }} poster="/images/poster-sintel.png" controls>
          <source src="/publicfiles/sintel/sintel-morgenstern.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="gridFraction">
        Eröffnungssequenz nur mit Musik<br />
        Musik: Jan Morgenstern
      </div>      
    </div>
    <Helmet>
      <title>OER | Ulrich Kaiser - Sintel Projekt</title>
      <meta name="description" content="Hier finden Sie Vertonungen der Eröffnungssequenz des Films Sintel von Blender zum einem OpenBook (OER) Filmmusik von Ulrich Kaiser." />
      <meta name="keywords" content="Filmmusik, Sintel, OER, Open Educational Resources, OpenBooks" />
      <meta name="copyright" content="Ulrich Kaiser, 2011" />
    </Helmet>
  </Layout>
  )
}

